import React from 'react';
import logo from '../../assets/images/logo.png'

const Logo = ({ white, ...props }) => {
  return (
    <img src={logo} alt="logo"  width={185.92} height={36.62} style={{objectFit: 'contain'}}/>
  );
};

export default Logo;
