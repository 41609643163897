import facebook from 'assets/images/icons/facebook.png';
import twitter from 'assets/images/icons/twitter.png';
import github from 'assets/images/icons/github.png';
import dribbble from 'assets/images/icons/dribbble.png';

export const menuItems = [
  {
    id: 2,
    title: 'Services',
    items: [
      {
        path: 'https://imakeable.com/en/it-services/web-applications',
        label: 'Web Applications',
      },
      {
        path: 'https://imakeable.com/en/it-services/mobile-applications',
        label: 'Mobile Applications',
      },
      {
        path: 'https://imakeable.com/en/it-services/software-testing',
        label: 'QA & Testing',
      },
      {
        path: 'https://imakeable.com/en/it-services/it-consultancy',
        label: 'IT Consultancy',
      },
      {
        path: 'https://imakeable.com/en/it-services/it-outsourcing',
        label: 'IT Outsourcing',
      },
    ],
  },
  {
    id: 3,
    title: 'Technology stack',
    items: [
      {
        path: 'https://imakeable.com/en/technology-stack/angular',
        label: 'Angular ',
      },
      {
        path: 'https://imakeable.com/en/technology-stack/aws',
        label: 'AWS',
      },
      {
        path: 'https://imakeable.com/en/technology-stack/electron',
        label: 'Electron.js',
      },
      {
        path: 'https://imakeable.com/en/technology-stack/nodejs',
        label: 'Node.js',
      },
      {
        path: 'https://imakeable.com/en/technology-stack/python',
        label: 'Python',
      },
      {
        path: 'https://imakeable.com/en/technology-stack/react',
        label: 'React',
      },
    ],
  },
  {
    id: 4,
    title: 'Company',
    items: [
      {
        path: 'https://imakeable.com/en/contact-us',
        label: 'Schedule Call',
      }, 
      {
        path: 'https://imakeable.com/en/contact-us',
        label: 'Contact Us',
      },
      {
        path: 'mailto: sales@imakeable.com',
        label: 'sales@imakeable.com',
      },
      {
        path: 'https://www.linkedin.com/company/imakeable',
        label: 'Linkedin',
      },
      {
        path: 'https://facebook.com/iMakeable',
        label: 'Facebook',
      },
      {
        path: 'https://imakeable.com/en/privacy-policy',
        label: 'Privacy Policy',
      },
    ],
  },
];

export const footerNav = [
  {
    path: '#!',
    label: 'Home',
  },
  {
    path: '#!',
    label: 'Advertise',
  },
  {
    path: '#!',
    label: 'Supports',
  },
  {
    path: '#!',
    label: 'Marketing',
  },
  {
    path: '#!',
    label: 'FAQ',
  },
];
