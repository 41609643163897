export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'services',
    label: 'Services',
  },
  {
    path: 'team',
    label: 'Team',
  },
  {
    path: 'why-us',
    label: 'Why Us',
  },
  {
    path: 'blog',
    label: 'Contact',
  }
];
